import React from 'react'

import styles from './Footer.module.css'

export default function Footer() {
  return (
    <div className={styles.root}>
      <div className={styles.contacts}>
        <div className={styles.phone}>
          <a href="tel:+79154505555">+7 915 450-55-55</a>
        </div>

        <div className={styles.email}>
          или <a href="mailto:d9154505555@gmail.com">d9154505555@gmail.com</a>
        </div>
      </div>

      <div className={styles.where}>
        Я живу в Москве, но с радостью проведу ваше торжество в любой точке мира!
      </div>

      <div className={styles.social}>
        <p>
          Фоловьте, комментируйте, репостите мои профили в социальных сетях
        </p>

        <ul>
          <li>
            <a href="https://www.instagram.com/dmitrysahno/" target="_blank">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/sahno.dmitry" target="_blank">Facebook</a>
          </li>
          <li>
            <a href="https://vk.com/dmitrysahno" target="_blank">Vkontakte</a>
          </li>
          <li>
            <a href="https://twitter.com/DmitrySahno" target="_blank">Twitter</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
