import React, { Component } from 'react'
import axios from 'axios'
import classNames from 'classnames'

import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'

import styles from './Slider.module.css'
import fonts from './Fonts.module.css'

class Slider extends Component {
  state = {
    reviews: null
  }

  ref = React.createRef()
  pag = React.createRef()

  componentDidMount = async () => {
    const res = await axios.get('index.json')
      this.setState({...res.data})

    this.swiper = new Swiper (this.ref.current, {pagination: {
        el: this.pag.current,
        clickable: true,
        bulletClass: styles.bullet,
        bulletActiveClass: styles.bullet_active
      }
    })
  }

  render() {
    const { reviews } = this.state

    return(
      <div className={styles.slider}>
        <div className={styles.desc}>
          Отзывы
        </div>

        <div ref={this.ref} className={classNames('swiper-container', styles.swiper)}>
          <div className={classNames('swiper-wrapper')}>
            {reviews &&
              <>
                {reviews.map((review, _) =>
                  <div key={_} className={classNames('swiper-slide', styles.slide)}>
                    <div className={classNames(styles.text)}>{review.text}</div>
                    <div className={styles.event}>{review.profiles}, {review.description}</div>
                  </div>
                )}
              </>
            }
          </div>
          <div ref={this.pag} className={classNames('swiper-pagination', styles.swiper_pagination)}></div>
        </div>

        <a href="/reviews/private" className={styles.button}>
          Прочитать отзывы
        </a>
      </div>
    )
  }
}

export default Slider
