import React, { Component } from 'react'
import classNames from 'classnames'
import styles from './Header.module.css'

function Link(props) {
  const path = window.location.pathname

  return <a href={props.href} className={classNames({[styles.active]: path == props.href})}>{props.children}</a>
}

class Header extends Component {
  state = {
    toggle: false
  }

  handleToggle = () => {
    this.setState(prevState => ({
      toggle: !prevState.toggle
    }))
  }

  render() {
    const { toggle } = this.state
    const { white } = this.props

    return (
      <div className={classNames(styles.root, {[styles.toggled]: toggle}, {[styles.white]: white})}>
        <div className={styles.overlay} onClick={this.handleToggle}></div>
        <div className={styles.burger} onClick={this.handleToggle}></div>
        <div className={styles.menu}>
          <ul>
            <li>
              <Link href="/">Обо мне</Link>
            </li>
            <li>
              <Link href="/calendar">Календарь</Link>
            </li>
            <li>
              <Link href="/travel">СахноTraveler</Link>
            </li>
            <li>
              <Link href="/corporative">Корпоративы</Link>
            </li>
            <li>
              <Link href="/wedding">Свадьбы</Link>
            </li>
            <li>
              <Link href="/reviews">Отзывы</Link>
            </li>
            <li>
              <Link href="/contacts">Контакты</Link>
            </li>
          </ul>
        </div>

        <div className={styles.phone}>
          <a href="tel:+79154505555">+7 915 450-55-55</a>
        </div>
      </div>
    )
  }
}

Header.defaultProps = {
  white: false
}

export default Header
