import React from 'react'
import classNames from 'classnames'
import axios from 'axios'

import Header from './Header'
import Footer from './Footer'
import Slider from './Slider'
import Instagram from './Instagram'

import styles from './Index.module.css'
import fonts from './Fonts.module.css'

export default function Index ({ event }) {
  return (
    <>
      <Header white={true} />

      <div className={styles.root}>
        <div className={styles.intro}>
          <a href="https://top100awards.ru/participant/dmitrij-sahno" target="_blank" className={styles.shield} />

          <div className={styles.slogan}>
            <h1>
              Дмитрий Сахно — Ведущий премиум событий в России и Мире!
            </h1>

            <p>
              #ХорошийВедущий
            </p>
          </div>
        </div>

        <div className={styles.who}>
          <ul>
            <li>
              Телеведущий Comedy Club Region на ТНТ4
            </li>
            <li>
              Участник проекта TOPSHOWMEN
            </li>
            <li>
              <a href="https://top100awards.ru/participant/dmitrij-sahno" target="_blank" className={styles.ss}>
                4-й в рейтинге ведущих Топ100Россия
              </a>
            </li>
          </ul>
        </div>

        <div className={styles.slider}>
          <Slider />
        </div>

        <div className={styles.sections}>
          <a href="/calendar" className={styles.calendar}>
            <div className={styles.placeholder}>
            </div>

            <div className={styles.text}>
              <div className={styles.desc}>
                Ближайшее мероприятие
              </div>

              {event &&
                <div className={classNames(styles.title, fonts.h2)}>
                  {event.text}
                </div>
              }

              <div className={styles.button}>
                Календарь ведущего
              </div>
            </div>
          </a>

          <a href="/travel" className={styles.traveler}>
           <div className={styles.placeholder}>
            </div>

            <div className={styles.text}>
              <div className={styles.desc}>
                Пишу свою страничку историй
              </div>

              <div className={classNames(styles.title, fonts.h2)}>
                Кипр — Это греция или турция?
              </div>

              <div className={styles.button}>
                СахноTraveler
              </div>
            </div>
          </a>

          <div className={styles.principles}>
            <div>
              <h2>
                Мои принципы
              </h2>
              <ul>
                <li>
                  Провести мероприятие так, чтобы захотелось быть на нём гостем!
                </li>

                <li>
                  Найти подход к каждому человеку, даже если их целый стадион.
                </li>

                <li>
                  Никогда не навязывать свою точку зрения ни во время подготовки, ни на самом торжестве — только делиться опытом и давать советы.
                </li>
              </ul>

              <p>
                Одним словом, Дмитрий Сахно - хороший ведущий. Пожалуй, это всё, что я хочу сказать о себе, когда на мне надет смокинг. А вот если он снят, начинается самое интересное...
              </p>

              <p>
                🌍 Обожаю путешествия. Посетил 57 стран. Мой ТОП - Норвегия, Исландия и Новая Зеландия. Ничего красивее природы этих стран я ещё не Земле не встречал. Как-то раз за месяц доехал на своей машине до самой западной точки Евразии- мыса Рока. Ну и вернулся обратно, конечно)
                Планирую остановиться только тогда, когда сотру все страны мира на скрэтч-карте, что подарили мне друзья.
              </p>

              <p>
                ⚽ Обожаю футбол и играю в него с самого детства! Непрофессионально! Зато с душой и всем сердцем!
                Создал FOOTBALL EVENT LEAGUE. Сейчас организую регулярный чемпионат по мини-футболу для более чем 200 парней из event-индустрии!
              </p>

              <p>
                🏊🏻‍♂️🚴🏼‍♂️🏃‍♂️ Готовлюсь стать IronMan'ом.
                В 2020м сделал три «половинки»- дистанции в 113 км, сейчас готовлюсь пройти все 226 км!
                Поэтому каждый вторник и пятницу в 7:00 я плаваю в бассейне “La Collina”, каждую среду и субботу - кручу педали в Триатлон Центре, а четверг и воскресенье - бегаю по парку «Крылатские холмы»!
              </p>

              <p>
                ⛵ Однажды попав на регату ведущим, я так увлёкся, что теперь сам являюсь шкипером с правами International Yachting Training!
                Так что если тебе надоел город, суета, цивилизация - давай возьмём лодку и пойдём под парусом встречать рассвет с бокальчиком Prosecco Doc, вдыхая солёный бриз Средиземного моря...
              </p>

              <p>
                ⛷🏂 На 25-летие друзья подарили мне доску, пришлось научиться кататься) 6 сезонов посвятил сноуборду, 5 - горным лыжам...
                Ты спросишь меня: «Кто круче? Лыжник или сноубордист?» Я отвечу: «В горах все равны.» Надену на ноги то, что сегодня по настроению, врублю на полную ранний Offspring и Limp Bizkit и гоу вниз по склону!
              </p>

              <p>
                🏐 Лето? Солнце? Жара? Тогда найти меня можно в Серебряном бору на одной из площадок для пляжного волейбола! Руками я играю хуже, чем ногами, но когда вокруг горячий песок, искрящиеся волны и ледяные коктейли это становится уже не столь важным...
              </p>

              <p>
                ⌚ Люблю часы. А если часы, то только швейцарские. В моей скромной коллекции Maurice Lacroix, Longines, Tag Heuer, Panerai, Vacheron Constantin.
                Абсолютно разные по стилю и настроению. Как и я ) Всегда разный, но педантичный и пунктуальный, точный в мелочах и деталях. А девиз мануфактуры Vacheron Constantin уже давно стал моим ориентиром по жизни: «Faire mieux si possible, ce qui est toujours possible (Всегда стремиться сделать лучше, если это возможно, а это возможно всегда)».
              </p>

              <p>
                🚙 Люблю машины. А если машины, то только BMW. Мир авто для меня делится на две части: красавцы с пропеллером на капоте и всё остальное.
              </p>

              <p>
                🍷 Люблю вино. А если вино, то только сухое. Белое из Италии, Португалии и Новой Зеландии. Красное из Франции, Аргентины и Грузии.
              </p>

              <p>
                👰🏼‍♀️🤵🏻‍♂️ Самая лучшая свадьба в моей жизни - это наша с женой. 3 фантастических дня в Грузии, турнир по гольфу на мальчишнике, написание картин под Rosé и Jamiroquai на девичнике, полеты на zip-line над Алазанской долиной, город любви Сигнахи, сбор молодого винограда своими руками, литры красного сухого из квеври...
                Если не знаете как занять вечер, то просто спросите меня: «А какой была твоя свадьба, Дим!?»
              </p>
            </div>
          </div>

          <a href="/corporative" className={styles.corporative}>
            <div className={styles.placeholder}>
            </div>

            <div className={styles.text}>
              <div className={classNames(styles.title, styles.h3)}>
                Ведущий на корпоратив
              </div>
            </div>
          </a>

          <a href="/wedding" className={styles.wedding}>
            <div className={styles.placeholder}>
            </div>

            <div className={styles.text}>
              <div className={classNames(styles.title, styles.h3)}>
                Ведущий на свадьбу
              </div>
            </div>
          </a>

          <a href="/wedding" className={styles.world}>
            <div className={styles.placeholder}>
            </div>

            <div className={styles.text}>
              <div className={classNames(styles.title, styles.h3)}>
                Ведущий без границ
              </div>
            </div>
          </a>
        </div>

        {/* <Instagram /> */}

      </div>

      <Footer />
    </>
  )
}
