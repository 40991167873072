import React, { Component } from 'react'
import axios from 'axios'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

import styles from './Instagram.module.css'

class Instagram extends Component {

  componentDidMount() {
    this._loadAsyncData()
  }

  componentWillUnmount() {
    if (this._asyncRequest) this._asyncRequest.cancel()
  }

  _loadAsyncData = async () => {
    this._asyncRequest = axios.CancelToken.source()

    const res = await axios.get('https://api.instagram.com/v1/users/243887644/media/recent/?access_token=243887644.813163d.7ffbb8e7f0554f37af2fb368ac020ad1&count=4')

    this.setState({ instagram: res.data.data })

    this._asyncRequest = null
  }

  state = {
    instagram: null
  }


  render(){
    const { instagram } = this.state

    return(
      <div>
        <div className={styles.instagram}>
          <h2>
            <a href="https://www.instagram.com/dmitrysahno/" target="_blank">@DMITRYSAHNO</a>
          </h2>

          {instagram &&
            <div className={styles.list}>
              {instagram.map(item =>
                <a key={item.id} href={`${item.link}`} target="_blank" className={styles.item}>
                  <div className={styles.meta}>
                    <div className={styles.likes}>{item.likes.count}</div>
                    <div className={styles.comments}>{item.comments.count}</div>
                  </div>

                  <div className={styles.date}>
                    {dayjs.unix(item.created_time).locale('ru').fromNow()}
                  </div>

                  <div className={styles.image} style={{ backgroundImage: `url(${item.images.standard_resolution.url})` }} />
                  <div>
                    {item.caption.text.slice(0, 160)}...
                  </div>
                </a>
              )}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default Instagram
