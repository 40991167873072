import React, { Component } from 'react'
import Header from './Header'
import axios from 'axios'
import classNames from 'classnames'

import styles from './Calendar.module.css'
import fonts from './Fonts.module.css'
import 'tippy.js/themes/light-border.css'

import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

import tippy from 'tippy.js'

class Calendar extends Component {
  state = {
    events: null
  }

  componentDidMount = async () => {
    this.start_date = dayjs().startOf('month').subtract(dayjs().month() % 4, 'month').subtract(4, 'month')
    this.end_date = this.start_date.add(16, 'month')

    const res = await axios.get('/calendar.json', {
      params: {
        start_date: this.start_date.toDate(),
        end_date: this.end_date.toDate()
      }
    })
      this.setState({...res.data})
  }

  render() {
    const { events } = this.state

    let month_array = Array.from(Array(dayjs(this.end_date).diff(this.start_date, 'month')), (_,x) => this.start_date.add(x, 'month'))
    const current_year = dayjs(this.start_date).year()

    if (!events) return null

    return(
      <>
        <Header />

        <div className={styles.calendars}>

          <div className={styles.legend}>
            <div className={styles.corporative}>Корпоративы</div>
            <div className={styles.wedding}>Свадьбы</div>
            <div className={styles.anniversary}>Семейные торжества</div>
            <div className={styles.travel}>Поездки, фестивали, путешествия</div>
          </div>

          <div className={styles.calendar_year}>
            <div className={styles.months}>
              {current_year &&
                <div className={styles.year}>{dayjs(this.start_date).year()}</div>
              }
              {month_array.map(month =>
                <>
                  {month.month() == 0 && month.year() != current_year &&
                    <div className={styles.year}>{month.year()}</div>
                  }
                  <Month startMonth={month} events={events.filter(event =>
                    (dayjs(event.start_date).isAfter(month) && dayjs(event.start_date).isBefore(month.endOf('month'))) ||
                    (dayjs(event.end_date).isAfter(month) && dayjs(event.end_date).isBefore(month.endOf('month'))) || dayjs(event.start_date).isSame(month))}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Calendar

class Month extends Component {
  render() {

    const { events, startMonth } = this.props

    const days_array = Array.from(Array(startMonth.daysInMonth()), (_,x) => startMonth.add(x, 'day'))
    const startOfMonth = startMonth.day()

    return(
      <div className={styles.month}>
        <div className={styles.monthtitle}>{startMonth.locale('ru').format('MMMM')}</div>
          {Array.from(Array(startOfMonth == 0 ? startOfMonth + 6 : startOfMonth - 1), () => <div />)}
          {days_array.map((day, _) =>
            <Day key={_} day={day} events={events.filter(event => dayjs(event.start_date).isSameOrBefore(day, 'day') && dayjs(event.end_date).isSameOrAfter(day, 'day'))} />
          )}
      </div>
    )
  }
}

class Day extends Component {

  ref = React.createRef()

  componentDidMount() {
    if (this.props.events.length > 0) {
      this.instance = new tippy(this.ref.current, {
        placement: 'top',
        arrow: true,
        content: this.props.events[0].text,
        theme: 'light-border'
      })
    }
  }

  render() {

    const { events, day} = this.props

    const today = day.isSame(dayjs(), 'day')

    const type = events.length > 0 ? events[0].event_type : false

    return(
        <div className={styles.date}>
          <div ref={this.ref} className={classNames(styles.day, {[styles.today]: today, [styles[type]]: type, [styles.occupied]: type})}>{this.props.day.format('D')}</div>
        </div>
    )
  }
}
